import "bootstrap/js/dist/collapse";
import { initAutoComplete } from "./complete";

import "../scss/main.scss";
import { auto } from "@popperjs/core";
import { Theme } from "./theme";

const ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
};
ready(() => {
  // Hide No-Js-Search-Form
  const noScript = document.getElementById("noscript");
  if (noScript) {
    noScript.classList.add("invisible");
  }

  // Handles light / dark theme
  new Theme();

  // Show autocomplete
  const autoComplete = document.getElementById("search-form");
  if (autoComplete) {
    initAutoComplete();
    autoComplete.classList.remove("invisible");
  }

  // Filter solutions by length
  const lengthBtn = document.querySelectorAll(
    ".accordion-collapse, .collapse, .show",
  );

  if (lengthBtn.length > 0) {
    const btnId = document.getElementById(
      document.querySelector(".first-letter").dataset.letterButton,
    );
    btnId.classList.add("active");
    let activeBtn = btnId;
    document
      .querySelectorAll(".accordion-collapse, .collapse, .show")
      .forEach((element) => {
        element.addEventListener("shown.bs.collapse", (event) => {
          activeBtn.classList.remove("active");
          activeBtn = document.getElementById(
            event.target.dataset.letterButton,
          );
          activeBtn.classList.add("active");
        });
      });
  }
});
